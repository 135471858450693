import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

import Home from './pages/home';
import S4W1 from './pages/weekOne';
import S4W2 from './pages/weekTwo';
import S4W3 from './pages/weekThree';

export default function App() {
  return (
    <Router>
      <div>
        {/* <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/users">Users</Link>
            </li>
          </ul>
        </nav> */}
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/weeks/1' element={<S4W1/>} />
          <Route path='/weeks/2' element={<S4W2/>} />
          <Route path='/weeks/3' element={<S4W3/>} />
        </Routes>
      </div>
    </Router>
  );
}