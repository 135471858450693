import '../App.css';

import Ticker from './components/ticker';
import RightColumn from './components/right_colum';
import StatsBox from './components/stats_box';

import { 
  Box,
  Center,
  Image,
  Link,
  Text,
  Stack,
  HStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react';

function S4W3() {
  return (
    <Box bgColor={'#E6EFF3'}>
      <Box 
        backgroundImage="url('/images/bg-2.png')"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        top='0'
        right='0'
        height='696px'
        left='0'
        pos={'fixed'}
      >
        <Stack direction={['column', 'row']} spacing={'10'}>
          <Link href='/'><Image src='/images/logo.png' alt='Comic Relief Racing' w='200px' mt='10' ml='10'/></Link>

          <Box pos='absolute' right='400px'>
            <Stack direction={['column', 'row']} spacing={'10'} pt={['0px', '50px']} textAlign='center'>
              
              <Image height="10px" width="110px" src='/images/sponsors/fanatec.png'  mt='5px'/>
              <Image height="22px" width="41px" src='/images/sponsors/rolex.png' />
              <Image height="18px" width="70px" src='/images/sponsors/pirelli.png' />
              <Image height="23px" width="70px" src='/images/sponsors/zoa.png' />
              <Image height="22px" width="70px" src='/images/sponsors/nlr.png' />
              
            </Stack>
          </Box>
        </Stack>

      </Box>

      <RightColumn/>

      <Box pt={['450px', '10']} pb='50' pos='relative' zIndex={2}>

        <StatsBox/>

        <Ticker/>

        <Box p={['5', '10']} bgColor={'white'}>
          <Text>Race Recaps</Text>

          <Box pt='10' maxW='850px'>
            <Text fontWeight={'bold'} color='#77A751' textTransform={'uppercase'}>Season 4 Week 3</Text>
            <Text fontWeight={'bold'} as='b' fontSize={'4xl'}>Monza</Text>

            <Center>
            <Stack direction={['column', 'row']} spacing={['20', '40']} color='#77A751'>
              <Box pos='relative'>
                <Text fontFamily={'Ramabhadra'} fontSize={'182px'} as='b'>P33</Text>
                <Box pos='absolute' w='250px' ml='100px' mt='-124px'>
                  <Image src='/images/car-981.png' alt='Car #981' />
                </Box>
              </Box>
              <Box pos='relative'>
                <Text fontFamily={'Ramabhadra'} fontSize={'182px'} as='b'>P14</Text>
                <Box pos='absolute' w='250px' ml='100px' mt='-124px'>
                  <Image src='/images/car-177.png' alt='Car #981' />
                </Box>
              </Box>
              
            </Stack>
            </Center>

            <Stack spacing='5' pt='10'>
              <Text>Unfortunately it seems like bad luck has followed us to Monza, with car #981 finishing P33 and car #177 finishing P14. Let's
                dig into the sessions and see what happened.
              </Text>
              <Image src='/images/photos/monza/805550_20220915141658_1.png' />
              <Text fontSize={"lg"} fontWeight='bold'>Qualifying</Text>
              <Text>Both cars had pretty solid pace coming into the qualifying session, with Shark hitting a 1:49.5 during the practice session
                right before. Quali was pretty uneventful, except for another car spinning right after T2 and Shark had to abandon what could've been
                a really great lap.
              </Text>
              <Text>At the end of quali, Shark qualified P32 with a time of 1:50.067 while Rogerio qualified P35 with a time of 1:50.300. The team
                was happy with the result and knew we'd go into the race with a strong pace from both cars.
              </Text>
              <Image src='/images/photos/monza/805550_20220915142239_1.png' />
              <Text fontSize={"lg"} fontWeight='bold'>The Race</Text>
              <Text>Monza is known for so many T1 incidents in the opening lap, but we were fortunate that most of the grid, all 45 cars, made through
                the first few turns with minimal contact. Shark's car was given a bump from behind, which did cause some aero damage, but for the most
                part both cars made it through without issues.
              </Text>
              <Text>This is where the luck ended for Shark, however. Coming out of T10, also known as Ascari, the rear of car #981 kicked out a bit and 
                with the amount of speed Shark was carrying, he unfortunately hit the wall with some force. This incident, which the earlier aero damage
                could have contributed to, forced Shark to pit the car to repair the damage.
              </Text>
              <Image src='/images/photos/monza/805550_20220915142653_1.png' />
              <Image src='/images/photos/monza/805550_20220915142720_1.png' />
              <Text>We did consider retiring car given the amount of damage, but Shark insisted on fixing the car and getting back out there. We went all
                the way to P45 and were two laps down from the field once repairs were done. But this didn't stop Shark from putting in his all and with
                strong pace and patience, was able to get the car back to P33. Almost at his original qualifying position.
              </Text>
              <Image src='/images/photos/monza/805550_20220915143013_1.png' />
              <Text>Rogerio had a great uneventful race, with a strong consistent pace and staying out of any harms way, he got his car into P14.
              </Text>
              <Image src='/images/photos/monza/805550_20220915142809_1.png' />
              <Image src='/images/photos/monza/805550_20220915143325_1.png' />
              <Text>Here's a quick run down of our qualifying and race results.</Text>
              <TableContainer border="1px solid #EEF2F6" rounded={'lg'}>
                <Table variant='simple'>
                  <TableCaption>
                    <Link color='#77A751' href="https://simresults.net/remote?result=https://accsm1.simracingalliance.com/results/download/220915_025606_R.json">View Detailed Race Breakdown</Link>
                  </TableCaption>
                  <Thead bgColor={'#FBFDFF'}>
                    <Tr>
                      <Th>Driver</Th>
                      <Th>Qualifying Time</Th>
                      <Th>Qualifying Pos</Th>
                      <Th>Finishing Position</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>#981 Land Shark</Td>
                      <Td>1:50.067 <Text fontSize={'sm'} color={'red.600'} as='span'>+1.4620</Text></Td>
                      <Td>32nd</Td>
                      <Td>33rd <Text fontSize={'sm'} color={'red.600'} as='span'>▼ 1</Text></Td>
                    </Tr>
                    <Tr>
                      <Td>#177 Rogerio Santos</Td>
                      <Td>1:50.300 <Text fontSize={'sm'} color={'red.600'} as='span'>+1.6950</Text></Td>
                      <Td>35th</Td>
                      <Td>14th <Text fontSize={'sm'} color={'green.600'} as='span'>▲ 21</Text></Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
              <Text>We are hopeful that our streak of bad luck ends in Monza. We are on our way from Italy to South Africa to start preparing
                for the next race at Kyalami. The weather is predicted to be pretty wet so we have some work ahead of us. See you then!
              </Text>
            </Stack>
          </Box>
        </Box>
        <Box pt='10'>
          <HStack spacing='20'>
            <Image height="20px" src="/images/sponsors/mclaren.png"/>
            <Image height="20px" src="/images/sponsors/vercel.png"/>
            <Image height="20px" src="/images/sponsors/sra.png"/>
          </HStack>
        </Box>
      </Box>
    </Box>
  );
}

export default S4W3;
