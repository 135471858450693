import Countdown from 'react-countdown';

import { 
  Box,
  Image,
  Text,
  Stack,
  HStack,
} from '@chakra-ui/react';

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <span>It is race day!</span>;
  } else {
    // Render a countdown
    return <span>{days}d  {hours}h  {minutes}m  {seconds}s</span>;
  }
};

function RightColumn() {
  return (
    <Box 
        pos={['relative', 'fixed']} 
        top={['430px', '0']} 
        right={['-3', '0']} 
        bottom={['', '0']} 
        color='#fff'
        w='364px'
        zIndex={'99'} 
      >
        <Stack spacing='0' height="100%">
          <Box bgColor={'#77A751'} textAlign='center' p='5'>
            <Text fontSize={'sm'}>Time till Kyalami</Text>
            <Text fontSize={'2xl'} fontWeight='black'><Countdown date={new Date('September 21, 2022 18:00:00')} renderer={renderer}/></Text>
          </Box>
          <Box bgColor={'rgba(0,0,0,.8)'} p='10' flex='1'>
            <Text>Upcoming Events</Text>
            <Box mt='26px' pl='5' borderLeft={"1px solid #575757"}>
              <Text fontSize='xl'>Kyalami</Text>
              <HStack>
                <Text color={'green.300'} fontSize={'xs'}>9/21/2022</Text>
                <Text color={'green.300'} fontSize={'xs'}>6PM PST</Text>
                <Text color={'green.300'} fontSize={'xs'}>2.814 miles</Text>
              </HStack>
              <Box p='10'>
                <Image w="190px" src="/images/tracks/kyalami.png" alt="Kyalami" />
              </Box>

              <Stack pt='10' spacing={'10'}>
                <Box>
                  <Text fontSize='md'>Hungaroring</Text>
                  <HStack>
                    <Text color={'green.300'} fontSize={'xs'}>9/28/2022</Text>
                    <Text color={'green.300'} fontSize={'xs'}>6PM PST</Text>
                    <Text color={'green.300'} fontSize={'xs'}>2.722 miles</Text>
                  </HStack>
                </Box>
                <Box>
                  <Text fontSize='md'>Watkins Glen</Text>
                  <HStack>
                    <Text color={'green.300'} fontSize={'xs'}>10/05/2022</Text>
                    <Text color={'green.300'} fontSize={'xs'}>6PM PST</Text>
                    <Text color={'green.300'} fontSize={'xs'}>2.350 miles</Text>
                  </HStack>
                </Box>
                <Box>
                  <Text fontSize='md'>Suzuka Circuit</Text>
                  <HStack>
                    <Text color={'green.300'} fontSize={'xs'}>10/12/2022</Text>
                    <Text color={'green.300'} fontSize={'xs'}>6PM PST</Text>
                    <Text color={'green.300'} fontSize={'xs'}>3.608 miles</Text>
                  </HStack>
                </Box>
                <Box>
                  <Text color={'red.300'} fontSize='xs'>FINAL RACE</Text>
                  <Text fontSize='md'>Circuit of the Americas</Text>
                  <HStack>
                    <Text color={'green.300'} fontSize={'xs'}>10/19/2022</Text>
                    <Text color={'green.300'} fontSize={'xs'}>6PM PST</Text>
                    <Text color={'green.300'} fontSize={'xs'}>2.300 miles</Text>
                  </HStack>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Stack>
      </Box>
  );
}

export default RightColumn;
