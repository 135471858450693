import '../App.css';

import Ticker from './components/ticker';
import RightColumn from './components/right_colum';
import StatsBox from './components/stats_box';

import { 
  Box,
  Center,
  Image,
  Link,
  Text,
  Stack,
  HStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react';

function S4W1() {
  return (
    <Box bgColor={'#E6EFF3'}>
      <Box 
        backgroundImage="url('/images/bg-2.png')"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        top='0'
        right='0'
        height='696px'
        left='0'
        pos={'fixed'}
      >
        <Stack direction={['column', 'row']} spacing={'10'}>
          <Link href='/'><Image src='/images/logo.png' alt='Comic Relief Racing' w='200px' mt='10' ml='10'/></Link>

          <Box pos='absolute' right='400px'>
            <Stack direction={['column', 'row']} spacing={'10'} pt={['0px', '50px']} textAlign='center'>
              
              <Image height="10px" width="110px" src='/images/sponsors/fanatec.png'  mt='5px'/>
              <Image height="22px" width="41px" src='/images/sponsors/rolex.png' />
              <Image height="18px" width="70px" src='/images/sponsors/pirelli.png' />
              <Image height="23px" width="70px" src='/images/sponsors/zoa.png' />
              <Image height="22px" width="70px" src='/images/sponsors/nlr.png' />
              
            </Stack>
          </Box>
        </Stack>

      </Box>

      <RightColumn/>

      <Box pt={['450px', '10']} pb='50' pos='relative' zIndex={2}>

        <StatsBox/>

        <Ticker/>

        <Box p={['5', '10']} bgColor={'white'}>
          <Text>Race Recaps</Text>

          <Box pt='10' maxW='850px'>
            <Text fontWeight={'bold'} color='#77A751' textTransform={'uppercase'}>Season 4 Week 1</Text>
            <Text fontWeight={'bold'} as='b' fontSize={'4xl'}>Circuit de Spa-Francorchamps</Text>

            <Center>
            <Stack direction={['column', 'row']} spacing={['20', '40']} color='#77A751'>
            <Box pos='relative'>
                <Text fontFamily={'Ramabhadra'} fontSize={'182px'} as='b'>P30</Text>
                <Box pos='absolute' w='250px' ml='100px' mt='-124px'>
                  <Image src='/images/car-981.png' alt='Car #981' />
                </Box>
              </Box>
              <Box pos='relative'>
                <Text fontFamily={'Ramabhadra'} fontSize={'182px'} as='b'>P3</Text>
                <Box pos='absolute' w='250px' ml='100px' mt='-124px'>
                  <Image src='/images/car-177.png' alt='Car #981' />
                </Box>
              </Box>
              
            </Stack>
            </Center>

            <Stack spacing='5' pt='10'>
              <Text>With the massive grid of 46 drivers, the race at Spa was full of excitement and some heart break. Rogerio qualified 19th with a time of 2:19.895, while car 
              #981 Land Shark, qualified 39th with a time of 2:20.910. During the opening laps both cars was able to pick up a few places, 
              with Rogerio getting to 5th and Shark making his way to 25th.</Text>
              <Image src='/images/photos/spa/805550_20220903104850_1.jpg' />
              <Text>With an unfortunate racing incident with car #92, Shark suffered front aero damage to his car. Resulting in a slightly slower
                pace. We decided to not pit early and instead wait for the pits to open at the 30 minute mark, hoping to mitigate any further loss in position. During
                lap 4 and turn 7, the the lack of balance caused Shark to spin the car. Dropping him back to 30th.
              </Text>
              <Text>Shark was able to maintain this position for the rest of the race with some exciting battles throughout.</Text>
              <Image src='/images/photos/spa/805550_20220903105054_1.jpg' />
              <Text>Rogerio had a super opening race with a finish in P3. With clean racing and consistent pace he was able to play his car and 
                get the team their first podium. Setting the theme for Season 4.
              </Text>
              <Image src='/images/photos/spa/805550_20220903110932_1.jpg' />
              <TableContainer border="1px solid #EEF2F6" rounded={'lg'}>
                <Table variant='simple'>
                  <TableCaption>
                    <Link color='#77A751' href="https://simresults.net/remote?result=https://accsm1.simracingalliance.com/results/download/220901_025602_R.json">View Detailed Race Breakdown</Link>
                  </TableCaption>
                  <Thead bgColor={'#FBFDFF'}>
                    <Tr>
                      <Th>Driver</Th>
                      <Th>Qualifying Time</Th>
                      <Th>Qualifying Pos</Th>
                      <Th>Finishing Position</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>#981 Land Shark</Td>
                      <Td>2:20.910 <Text fontSize={'sm'} color={'red.600'} as='span'>+2.3450</Text></Td>
                      <Td>39th</Td>
                      <Td>30th <Text fontSize={'sm'} color={'green.600'} as='span'>▲ 9</Text></Td>
                    </Tr>
                    <Tr>
                      <Td>#177 Rogerio Santos</Td>
                      <Td>2:19.895 <Text fontSize={'sm'} color={'red.600'} as='span'>+1.3300</Text></Td>
                      <Td>19th</Td>
                      <Td>3rd <Text fontSize={'sm'} color={'green.600'} as='span'>▲ 17</Text></Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </Stack>
          </Box>
        </Box>
        <Box pt='10'>
          <HStack spacing='20'>
            <Image height="20px" src="/images/sponsors/mclaren.png"/>
            <Image height="20px" src="/images/sponsors/vercel.png"/>
            <Image height="20px" src="/images/sponsors/sra.png"/>
          </HStack>
        </Box>
      </Box>
    </Box>
  );
}

export default S4W1;
