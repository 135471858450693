import '../App.css';

import { PopupButton } from '@typeform/embed-react'

import RightColumn from './components/right_colum';
import Ticker from './components/ticker';

import StatsBox from './components/stats_box';

import { 
  Box,
  Button,
  Center,
  Image,
  Link,
  Text,
  Stack,
  HStack,
  LinkBox,
  LinkOverlay,
  Wrap, 
  WrapItem
} from '@chakra-ui/react';

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <span>It is race day!</span>;
  } else {
    // Render a countdown
    return <span>{days}d  {hours}h  {minutes}m  {seconds}s</span>;
  }
};

const TeamCarousel = ({pos, teamName, points, bold}) => {
  return (
    <Box p='5'>
      <Text fontSize={'sm'} fontWeight="bold"><Text as='span' color='#F89234' fontWeight={'bold'} p='2'>{pos}</Text> {teamName}</Text>
    </Box>
  )
}

function Home() {
  return (
    <Box h='calc(100vh)' bgColor={'#000'} bgImage="/images/bg-3.png">
      <Center pt='20'>
        <Stack spacing='20'>
          <Center>
            <Image src="/images/logo.png" w='130px'/>
          </Center>
          <Text textAlign={'center'} color='#FFF' fontSize='lg' w='650px'>Recruiting is currently closed. See ya&apos;ll soon.</Text>
          <Box>
          </Box>
        </Stack>
      </Center>
    </Box>
  );
}

export default Home;
