import '../App.css';

import Ticker from './components/ticker';
import RightColumn from './components/right_colum';
import StatsBox from './components/stats_box';

import { 
  Box,
  Center,
  Image,
  Link,
  Text,
  Stack,
  HStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react';

function S4W2() {
  return (
    <Box bgColor={'#E6EFF3'}>
      <Box 
        backgroundImage="url('/images/bg-2.png')"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        top='0'
        right='0'
        height='696px'
        left='0'
        pos={'fixed'}
      >
        <Stack direction={['column', 'row']} spacing={'10'}>
          <Link href='/'><Image src='/images/logo.png' alt='Comic Relief Racing' w='200px' mt='10' ml='10'/></Link>

          <Box pos='absolute' right='400px'>
            <Stack direction={['column', 'row']} spacing={'10'} pt={['0px', '50px']} textAlign='center'>
              
              <Image height="10px" width="110px" src='/images/sponsors/fanatec.png'  mt='5px'/>
              <Image height="22px" width="41px" src='/images/sponsors/rolex.png' />
              <Image height="18px" width="70px" src='/images/sponsors/pirelli.png' />
              <Image height="23px" width="70px" src='/images/sponsors/zoa.png' />
              <Image height="22px" width="70px" src='/images/sponsors/nlr.png' />
              
            </Stack>
          </Box>
        </Stack>

      </Box>

      <RightColumn/>

      <Box pt={['450px', '10']} pb='50' pos='relative' zIndex={2}>

        <StatsBox/>

        <Ticker/>

        <Box p={['5', '10']} bgColor={'white'}>
          <Text>Race Recaps</Text>

          <Box pt='10' maxW='850px'>
            <Text fontWeight={'bold'} color='#77A751' textTransform={'uppercase'}>Season 4 Week 2</Text>
            <Text fontWeight={'bold'} as='b' fontSize={'4xl'}>Circuit Zandvoort</Text>

            <Center>
            <Stack direction={['column', 'row']} spacing={['20', '40']} color='#77A751'>
              <Box pos='relative'>
                <Text fontFamily={'Ramabhadra'} fontSize={'182px'} as='b'>P30</Text>
                <Box pos='absolute' w='250px' ml='100px' mt='-124px'>
                  <Image src='/images/car-981.png' alt='Car #981' />
                </Box>
              </Box>
              <Box pos='relative'>
                <Text fontFamily={'Ramabhadra'} fontSize={'182px'} as='b'>P17</Text>
                <Box pos='absolute' w='250px' ml='100px' mt='-124px'>
                  <Image src='/images/car-177.png' alt='Car #981' />
                </Box>
              </Box>
              
            </Stack>
            </Center>

            <Stack spacing='5' pt='10'>
              <Text>Our week at Zandvoort was a very tough one for the team. Both drivers showed really good pace leading up to the 
                race with competitive times. unfortunately quali didn't really go our way, with a P23 for Santos and a P33 for Shark.
              </Text>
              <Image src='/images/photos/zandvoort/805550_20220907203646_1.png' />
              <Text>Shark, car #981, was able to pick up a few positions in the first few corners of the opening lap. But unfortunately was met with a 
                spun out Ferrari. In an effort to avoid the car, Shark slowed down and made a slight adjustment to the right. At the same time, car
                #139 moved to the inside and collided with Shark. This caused the car to be bumped onto the grass and slamming into the wall.
              </Text>
              <Text>Although the damage caused lack of downforce in the front, the team decided to not fix as Shark could keep a fairly competitive
                pace and maintaining his current position. It was an incredibly long and exhausting hour for Shark trying to get that car to 
                the end.</Text>
              <Image src='/images/photos/zandvoort/805550_20220907203844_1.png' />
              <Text>Rogerio was able to avoid the Ferrari and the cars around him. Keeping a fast pace was able to get his car to P17.</Text>
              <TableContainer border="1px solid #EEF2F6" rounded={'lg'}>
                <Table variant='simple'>
                  <TableCaption>
                    <Link color='#77A751' href="https://simresults.net/remote?result=https://accsm1.simracingalliance.com/results/download/220908_025212_R.json">View Detailed Race Breakdown</Link>
                  </TableCaption>
                  <Thead bgColor={'#FBFDFF'}>
                    <Tr>
                      <Th>Driver</Th>
                      <Th>Qualifying Time</Th>
                      <Th>Qualifying Pos</Th>
                      <Th>Finishing Position</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>#981 Land Shark</Td>
                      <Td>01:37.295 <Text fontSize={'sm'} color={'red.600'} as='span'>+2.0450</Text></Td>
                      <Td>33rd</Td>
                      <Td>30th <Text fontSize={'sm'} color={'green.600'} as='span'>▲ 3</Text></Td>
                    </Tr>
                    <Tr>
                      <Td>#177 Rogerio Santos</Td>
                      <Td>01:36.670 <Text fontSize={'sm'} color={'red.600'} as='span'>+1.4200</Text></Td>
                      <Td>23rd</Td>
                      <Td>19th <Text fontSize={'sm'} color={'green.600'} as='span'>▲ 4</Text></Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
              <Text>Although not the result we wanted, both cars were able to finish and bring in some points. So we keep our heads up as we head over to Monza
                next week.
              </Text>
            </Stack>
          </Box>
        </Box>
        <Box pt='10'>
          <HStack spacing='20'>
            <Image height="20px" src="/images/sponsors/mclaren.png"/>
            <Image height="20px" src="/images/sponsors/vercel.png"/>
            <Image height="20px" src="/images/sponsors/sra.png"/>
          </HStack>
        </Box>
      </Box>
    </Box>
  );
}

export default S4W2;
