import Marquee from "react-fast-marquee";

import { 
  Box,
  Text,
  HStack,
} from '@chakra-ui/react';

const TeamCarousel = ({pos, teamName, points, bold}) => {
  return (
    <Box p='5'>
      <Text fontSize={'sm'} fontWeight="bold"><Text as='span' color='#F89234' fontWeight={'bold'} p='2'>{pos}</Text> {teamName}</Text>
    </Box>
  )
}

function StatsBox() {
  return (
    <Box bgColor={'#000'} p='6' color='#fff' mr='364px' mt='120px'>
      <HStack spacing='10'>
        <Box flex='1'>
          <Text fontSize={'sm'}>Overall Position</Text>
          <Text fontSize={'xl'} fontWeight='bold' fontFamily={'Bungee'}>8th</Text>
        </Box>
        <Box flex='1'>
          <Text fontSize={'sm'}>Overall Points</Text>
          <Text fontSize={'xl'} fontWeight='bold' fontFamily={'Bungee'}>163</Text>
        </Box>
        <Box flex='1'>
          <Text fontSize={'sm'}># of Teams</Text>
          <Text fontSize={'xl'} fontWeight='bold' fontFamily={'Bungee'}>25</Text>
        </Box>
        <Box flex='1'>
          <Text fontSize={'sm'}>Season</Text>
          <Text fontSize={'xl'} fontWeight='bold' fontFamily={'Bungee'}>4</Text>
        </Box>
        <Box flex='1'>
          <Text fontSize={'sm'}>Week</Text>
          <Text fontSize={'xl'} fontWeight='bold' fontFamily={'Bungee'}>2</Text>
        </Box>
      </HStack>
    </Box>
  );
}

export default StatsBox;
