import Marquee from "react-fast-marquee";

import { 
  Box,
  Text,
  Stack,
} from '@chakra-ui/react';

const TeamCarousel = ({pos, teamName, points, bold}) => {
  return (
    <Box p='5'>
      <Text fontSize={'sm'} fontWeight="bold"><Text as='span' color='#F89234' fontWeight={'bold'} p='2'>{pos}</Text> {teamName}</Text>
    </Box>
  )
}

function Ticker() {
  return (
    <Stack direction={'row'} spacing='10' bgColor={'#E6EFF3'}>
      <Marquee gradientColor={[230,239,243]}  pauseOnHover={true}>
      <TeamCarousel pos="#01" teamName="Team McLaren - Pod Racers" />
      <TeamCarousel pos="#02" teamName="Team McLaren - ISMC" />
      <TeamCarousel pos="#03" teamName="Lazy Cats Racing" />
      <TeamCarousel pos="#04" teamName="Oh Boy! Racing Div 2" />
      <TeamCarousel pos="#05" teamName="Lil' Fellas" />
      <TeamCarousel pos="#06" teamName="Comic Relief Racing" bold/>
      <TeamCarousel pos="#07" teamName="Team McLaren - Aperture" />
      <TeamCarousel pos="#08" teamName="Cruisin USA Official Team Racing" />
      <TeamCarousel pos="#09" teamName="Redshift Racing" />
      <TeamCarousel pos="#10" teamName="Podium Hunters" />
      <TeamCarousel pos="#11" teamName="Runtime Terror" />
      <TeamCarousel pos="#12" teamName="Grid Thieves Motorsports II" />
      <TeamCarousel pos="#13" teamName="Meatballers" />
      <TeamCarousel pos="#14" teamName="OMG AMG" />
      <TeamCarousel pos="#15" teamName="Shake N' Bake" />
      <TeamCarousel pos="#16" teamName="Online Racing Collective" />
      <TeamCarousel pos="#17" teamName="Momofine" />
      <TeamCarousel pos="#18" teamName="Squadra Corse" />
      <TeamCarousel pos="#19" teamName="BBC II" />
      <TeamCarousel pos="#20" teamName="Ghost Rider Racing Alpha" />
      <TeamCarousel pos="#21" teamName="Doug's Digits" />
      <TeamCarousel pos="#22" teamName="Plan E Racing II" />
      <TeamCarousel pos="#23" teamName="917 Project" />
      <TeamCarousel pos="#24" teamName="Crazy Rabbits Racing" />
      <TeamCarousel pos="#25" teamName="Flying Fotographic Ferraris D2" />
      </Marquee>
    </Stack>
  );
}

export default Ticker;
